html {
  scroll-behavior: smooth;
}

body {
	color: #3a3a3a;
	background-color: #f5f5f5;
}

.btn {
	background-color: #005cbf;
	border-color: #005cbf;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.btn:hover {
	background-color: #343a40;
	border-color: #343a40;
}

/*--- navigation bar ---*/

.navbar {
	width: 100%;
	background-color: white;
	padding: .5rem;
}

.navbar-header {
	max-width: 1200px;
	margin: auto;
}

.logo {
	width: 100px;
	height: 100px;
	fill: #0c2344;
}

.nav-link,
.navbar-brand {
	color: #3a3a3a;
	cursor: pointer;
	margin-left: 0.5em;
}

.nav-link {
	margin-right: 0.5em !important;
}

.nav-link:hover {
	color: #2d83ff;
}

.navbar-collapse {
	justify-content: flex-end;
}

.nav-active-link {
	color: #2d83ff;
}

/*--- content ---*/

h2 {
	font-size: 1.5em;
}

h3 {
	font-size: 1em;
	font-weight: bold;
}

.content-area {
	position: relative;
	margin-top: 50px;
	background-color: white;
	max-width: 1200px;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (max-width : 768px) {
	.content {
	padding: 3em 0.5em !important;
	}
}

@media only screen and (max-width : 360px) {
	body {
	font-size: 0.8em;
	}
	
	h1 {
	font-size: 2em;
	}
}
	
/*--- footer ---*/

footer {
	position: relative;
	margin-top: 50px;
}

footer .icon {
	color: white;
	text-decoration: none;
}
